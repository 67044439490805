<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>
    <b-card>
      <b-row class="mb-2">

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Province"
            label-for="filter_province"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <v-select
              v-model="selected.province"
              input-id="filter_province"
              :options="list.provinces"
              label="province_name"
              small
              placeholder="search province here"
            >
              <template #option="{ province_name }">
                <span>{{ province_name }}</span>
              </template>
              <template #no-options="">
                No Available Provinces
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Municipality"
            label-for="filter_municipality"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <v-select
              v-model="selected.municipality"
              input-id="filter_municipality"
              :options="list.municipalities"
              label="municipality_name"
              small
              placeholder="search municipality here"
            >
              <template #option="{ municipality_name }">
                <span>{{ municipality_name }}</span>
              </template>
              <template #no-options="">
                No Available Municipalities
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Crop"
            label-for="filter_crop"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <v-select
              v-model="selected.filter_crop"
              input-id="filter_crop"
              :options="filterCrops"
              label="crop_name"
              small
              :disabled="state.busy"
              placeholder="search crop here"
            >
              <template #option="{ crop_name }">
                <span>{{ crop_name }}</span>
              </template>
              <template #no-options="">
                No Available Crops
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Land Size"
            label-for="filter_field_size"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <v-select
              v-model="selected.filter_field_size"
              input-id="filter_field_size"
              :options="filterFieldSizes"
              label="field_size_name"
              small
              :disabled="(state.busy || selected.filter_crop === null)"
              placeholder="search land size here"
            >
              <template #option="{ field_size_name }">
                <span>{{ field_size_name }}</span>
              </template>
              <template #no-options="">
                No Available Land Sizes
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            label="Search"
            label-for="filter_search"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <b-input
              id="filter_search"
              v-model="tableLead.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>

        <b-col
          cols="12"
        >

          <b-table
            ref="table"
            small
            hover
            responsive
            show-empty
            class="mt-2"
            :stacked="isMobile()"
            :per-page="tableLead.perPage"
            :current-page="tableLead.currentPage"
            :items="tableLeadProvider"
            :fields="tableLead.fields"
            :sort-by.sync="tableLead.sortBy"
            :sort-desc.sync="tableLead.sortDesc"
            :sort-direction="tableLead.sortDirection"
            :filter="tableLead.filter"
            :filter-included-fields="tableLead.filterOn"
            :busy="tableLead.busy"
          >
            <template #cell(index)="data">
              {{ tableLead.currentPage * tableLead.perPage - tableLead.perPage + (data.index + 1) }}
            </template>

            <template #cell(active)="{ value }">
              {{ Number(value) == 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell(submitted_by)="data">
              <div class="text-nowrap">
                {{ `${data.item.owner?.first_name || ''} ${data.item.owner?.last_name || ''}` }}
              </div>
            </template>

            <template #cell()="data">
              <div class="text-nowrap">
                {{ data.value }}
              </div>
            </template>

            <template #cell(actions)="row">
              <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
                <b-button
                  size="sm"
                  class="mr-0 mr-md-1 mb-1 mb-md-0"
                  @click="onShowDetailForm(row.item)"
                >
                  View
                </b-button>

                <b-button
                  v-if="userScope === 'lead'"
                  size="sm"
                  variant="success"
                  class="mr-0 mr-md-1 mb-1 mb-md-0"
                  @click="onShowLogAddForm(row.item)"
                >
                  Log Visit
                </b-button>

                <b-button
                  size="sm"
                  variant="warning"
                  @click="onShowAddCallRequest(row.item)"
                >
                  Call Request
                </b-button>

              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>
          </b-table>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableLead.perPage"
              :options="tableLead.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableLead.currentPage"
            :total-rows="tableLead.totalRows"
            :per-page="tableLead.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-view-detail"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      modal-class="fullscreen"
    >
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
      >
        <b-tab
          title="Customer Information"
        >
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="first_name">First Name</label>
                <b-input
                  id="first_name"
                  v-model="customer.first_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="last_name">Last Name</label>
                <b-input
                  id="last_name"
                  v-model="customer.last_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="mobile_number">Mobile Number</label>
                <b-input
                  id="mobile_number"
                  v-model="customer.mobile_number"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="gender">Gender</label>
                <b-input
                  id="gender"
                  v-model="customer.gender"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="cluster_name">Cluster</label>
                <b-input
                  id="cluster_name"
                  v-model="customer.cluster_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="area_name">Area</label>
                <b-input
                  id="area_name"
                  v-model="customer.area_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="region_name">Region</label>
                <b-input
                  id="region_name"
                  v-model="customer.region_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="province_name">Province</label>
                <b-input
                  id="province_name"
                  v-model="customer.province_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="municipality_name">Municipality</label>
                <b-input
                  id="municipality_name"
                  v-model="customer.municipality_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="barangay_name">Barangay</label>
                <b-input
                  id="barangay_name"
                  v-model="customer.barangay_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="12"
            >
              <b-form-group>
                <label for="farm_address">Farm Address</label>
                <b-textarea
                  id="farm_address"
                  v-model="customer.farm_address"
                  rows="6"
                  max-rows="6"
                  disabled
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-tab>

        <b-tab
          title="Customer Photo"
        >
          <b-row>
            <ValidationObserver
              ref="formUploadPhoto"
              class="col-12 d-flex justify-content-center mt-3"
            >
              <form
                role="form"
                novalidate
                class="col-md-4 col-12"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="d-flex flex-column justify-content-center align-items-center"
                  >
                    <b-img
                      fluid
                      height="350px"
                      width="350px"
                      :src="customer.photo_url"
                      :style="{ width: '350px', height: '350px', border: '2px solid black' }"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="file"
                      vid="file"
                      :rules="rulesFile"
                    >
                      <b-form-file
                        v-model="customerPhoto.file"
                        accept=".jpg, .jpeg, .png"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                        multiple
                        class="mt-2"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <b-button
                      variant="success"
                      class="mt-2"
                      @click="onUploadPhotoButtonClick"
                    >
                      Upload Photo
                    </b-button>
                  </b-col>
                </b-row>
              </form>
            </ValidationObserver>
          </b-row>
        </b-tab>

        <b-tab title="Crop Details">
          <b-row>
            <b-col
              cols="12"
              class="mt-2"
            >
              <b-table
                ref="table-crop-details"
                small
                hover
                responsive
                show-empty
                :stacked="isMobile()"
                :fields="tableCropDetails.fields"
                :items="filteredCustomerCropDetails"
              >
                <template #cell(index)="data">
                  {{ tableCropDetails.currentPage * tableCropDetails.perPage - tableCropDetails.perPage + (data.index + 1) }}
                </template>

                <template #cell()="data">
                  <div class="text-nowrap">
                    {{ data.value }}
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center py-5">
                    <b-icon
                      icon="stopwatch"
                      font-scale="5"
                      animation="cylon"
                    />
                    <p class="h3 py-2">
                      <strong>Loading . . .</strong>
                    </p>
                  </div>
                </template>

              </b-table>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Marketing Programs">
          <b-row>
            <b-col
              cols="12"
              class="mt-2"
            >
              <b-table
                ref="table-marketing-programs"
                small
                hover
                responsive
                show-empty
                :stacked="isMobile()"
                :fields="tableMarketingPrograms.fields"
                :items="filteredCustomerMarketingPrograms"
              >
                <template #cell(index)="data">
                  {{ tableMarketingPrograms.currentPage * tableMarketingPrograms.perPage - tableMarketingPrograms.perPage + (data.index + 1) }}
                </template>

                <template #cell()="data">
                  <div class="text-nowrap">
                    {{ data.value }}
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          title="Communication Logs"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-table
                ref="customerLogTable"
                small
                hover
                responsive
                show-empty
                class="mt-2"
                :stacked="isMobile()"
                :per-page="tableCommunicationLog.perPage"
                :current-page="tableCommunicationLog.currentPage"
                :items="tableCommunicationLogProvider"
                :fields="tableCommunicationLog.fields"
                :sort-by.sync="tableCommunicationLog.sortBy"
                :sort-desc.sync="tableCommunicationLog.sortDesc"
                :sort-direction="tableCommunicationLog.sortDirection"
                :filter="tableCommunicationLog.filter"
                :filter-included-fields="tableCommunicationLog.filterOn"
                :busy="tableCommunicationLog.busy"
              >

                <template #cell(index)="data">
                  {{ tableCommunicationLog.currentPage * tableCommunicationLog.perPage - tableCommunicationLog.perPage + (data.index + 1) }}
                </template>

                <template #cell()="data">
                  <div class="text-nowrap">
                    {{ data.value }}
                  </div>
                </template>

                <template #cell(action)="row">
                  <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
                    <b-button
                      size="sm"
                      @click="onViewCommunicationLog(row.item)"
                    >
                      View
                    </b-button>
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center py-5">
                    <b-icon
                      icon="stopwatch"
                      font-scale="5"
                      animation="cylon"
                    />
                    <p class="h3 py-2">
                      <strong>Loading . . .</strong>
                    </p>
                  </div>
                </template>

              </b-table>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableCommunicationLog.perPage"
                  :options="tableCommunicationLog.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableCommunicationLog.currentPage"
                :total-rows="tableCommunicationLog.totalRows"
                :per-page="tableCommunicationLog.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          title="Visitation Logs"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-table
                ref="visitationLogTable"
                small
                hover
                responsive
                show-empty
                class="mt-2"
                :stacked="isMobile()"
                :per-page="tableVisitationLog.perPage"
                :current-page="tableVisitationLog.currentPage"
                :items="tableVisitationLogProvider"
                :fields="tableVisitationLog.fields"
                :sort-by.sync="tableVisitationLog.sortBy"
                :sort-desc.sync="tableVisitationLog.sortDesc"
                :sort-direction="tableVisitationLog.sortDirection"
                :filter="tableVisitationLog.filter"
                :filter-included-fields="tableVisitationLog.filterOn"
                :busy="tableVisitationLog.busy"
              >

                <template #cell(index)="data">
                  {{ tableVisitationLog.currentPage * tableVisitationLog.perPage - tableVisitationLog.perPage + (data.index + 1) }}
                </template>

                <template #cell()="data">
                  <div class="text-nowrap">
                    {{ data.value }}
                  </div>
                </template>

                <template #cell(action)="row">
                  <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
                    <b-button
                      size="sm"
                      @click="onViewVisitationLog(row.item)"
                    >
                      View Details
                    </b-button>
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center py-5">
                    <b-icon
                      icon="stopwatch"
                      font-scale="5"
                      animation="cylon"
                    />
                    <p class="h3 py-2">
                      <strong>Loading . . .</strong>
                    </p>
                  </div>
                </template>

              </b-table>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableVisitationLog.perPage"
                  :options="tableVisitationLog.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableVisitationLog.currentPage"
                :total-rows="tableVisitationLog.totalRows"
                :per-page="tableVisitationLog.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-communication-log"
      title="View Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposed At</label>
            <b-input
              :value="dateTimeFormatter(selected.log?.created_at)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Agent</label>
            <b-input
              :value="selected.log?.agent_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Channel Name</label>
            <b-input
              :value="selected.log?.channel_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Call Status</label>
            <b-input
              :value="selected.log?.call_status"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Unsuccessful Reason</label>
            <b-input
              :value="selected.log?.unsuccessful_reason_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition Type</label>
            <b-input
              :value="selected.log?.disposition_type_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition</label>
            <b-input
              :value="selected.log?.disposition_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Promotion</label>
            <b-input
              :value="selected.log?.promotion_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-log-visit"
      title="Log Visit"
      size="lg"
      scrollable
      no-close-on-backdrop
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="formLogVisit">
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="visitation date"
                vid="visitation_date"
                rules="required"
              >
                <div class="form-group">
                  <label for="visitation_date">Visitation Date</label>
                  <b-input
                    id="visitation_date"
                    v-model="visitationLog.visitation_date"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </b-col>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="purpose"
                vid="purpose"
                rules="required"
              >
                <div class="form-group">
                  <label for="purpose">Purpose</label>
                  <b-form-select
                    id="purpose"
                    v-model="visitationLog.purpose"
                    :options="filteredVisitationPurposes"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- Please Select Purpose --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required|max:1000"
              >
                <div class="form-group">
                  <label for="remarks">Remarks</label>
                  <b-textarea
                    id="remarks"
                    v-model="visitationLog.remarks"
                    :state="errors.length > 0 ? false : null"
                    rows="10"
                    max-rows="10"
                    placeholder="enter remarks here"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <ValidationObserver
        ref="formVisitationLogAttachment"
      >
        <form
          role="form"
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="visitation log attachments"
                vid="visitation_attachments"
                rules="required|image|length:1"
              >
                <b-form-group
                  label="Select Photo To Upload"
                  label-for="visitation_attachments"
                  label-class="font-weight-bolder"
                >
                  <b-form-file
                    id="visitation_attachments"
                    v-model="visitationLogAttachment.attachments"
                    multiple
                    accept=".jpg, .jpeg, .png"
                    :disabled="state.busy || visitationLog.visitation_log_attachments.length > 0"
                    :state="errors.length > 0 ? false : null"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
            >
              <b-button
                size="sm"
                class="mr-1"
                variant="success"
                :disabled="visitationLog.visitation_log_attachments.length > 0"
                @click="onValidateUploadVisitaionLogAttachments"
              >
                Upload Photos
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                :disabled="visitationLog.visitation_log_attachments.length === 0"
                @click="onDeleteAllVisitaionLogAttachment"
              >
                Delete Uploaded Photos
              </b-button>
            </b-col>
            <b-col
              cols="12"
              class="mt-2"
            >
              <b-table
                ref="tableVisitationLogAttachments"
                hover
                small
                striped
                responsive
                show-empty
                :stacked="isMobile()"
                :fields="tableVisitationLogAttachments.fields"
                :items="visitationLog.visitation_log_attachments"
              >
                <template #cell(index)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(action)="row">
                  <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
                    <b-button
                      size="sm"
                      class="mr-0 mr-md-1 mb-1 mb-md-0"
                      @click="onPreviewVisitationLogAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                    <b-button
                      size="sm"
                      variant="danger"
                      @click="onDeleteVisitationLogAttachment(row.item)"
                    >
                      Delete
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          class="mr-1"
          :disabled="state.busy"
          @click="ok()"
        >
          Submit
        </b-button>

        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-visitation-log"
      title="View Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Visited At</label>
            <b-input
              :value="dateFormatter(selected.visitation_log?.visitation_date)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>LGA / RFO</label>
            <b-input
              :value="selected.visitation_log?.user_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Purpose</label>
            <b-input
              :value="selected.visitation_log?.purpose_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.visitation_log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <p class="mb-1">
            Uploaded Photos
          </p>
          <b-table
            hover
            small
            striped
            responsive
            show-empty
            :stacked="isMobile()"
            :fields="tableVisitationLogAttachments.fields"
            :items="selected.visitation_log?.visitation_log_attachments"
          >
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(action)="row">
              <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
                <b-button
                  size="sm"
                  class="mr-0 mr-md-1 mb-1 mb-md-0"
                  @click="onPreviewVisitationLogAttachment(row.item)"
                >
                  Preview
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-add-call-request"
      title="Call Request"
      size="lg"
      scrollable
      no-close-on-backdrop
      @ok="onConfirmAddCallRequest"
    >
      <ValidationObserver ref="formCallRequest">
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label for="customer">Customer Name</label>
                <b-input
                  :value="callRequest.customer_name"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="mobile_number">Mobile Number</label>
                <b-input
                  :value="callRequest.mobile_number"
                  :disabled="true"
                />
              </b-form-group>

              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required"
              >
                <div class="form-group">
                  <label for="remarks">Remarks</label>
                  <b-textarea
                    id="remarks"
                    v-model="callRequest.remarks"
                    :state="errors.length > 0 ? false : null"
                    rows="10"
                    max-rows="10"
                    placeholder="enter remarks here"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          class="mr-1"
          :disabled="state.busy"
          @click="ok()"
        >
          Submit
        </b-button>

        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-attachment"
      :title="ModalAttachmentTitle"
      size="xl"
      scrollable
      no-close-on-backdrop
    >
      <b-img
        :src="visitationLogAttachment.preview_photo_path"
        fluid-grow
      />
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import { AxiosError, UserLeadService, UserLogService, UserCallRequestService, UserAttachmentService, SharedLogService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'
import url from '@/mixins/url'

export default {
  name: 'UserLeads',

  middleware: ['auth', 'user'],

  mixins: [formatter, misc, url],

  metaInfo: () => ({
    title: 'Leads'
  }),

  data () {
    return {
      state: {
        busy: false,
        previewing: false
      },
      filter: {
        province: 'all',
        municipality: 'all',
        filter_crop: 'all'
      },
      list: {
        purposes: [],
        provinces: [],
        municipalities: [],
        crops: [],
        field_sizes: []
      },
      selected: {
        log: null,
        visitation_log: null,
        province: null,
        municipality: null,
        filter_crop: null,
        filter_field_size: null
      },
      customer: {
        customer_type: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        mobile_number: '',
        gender: '',
        cluster_name: '',
        area_name: '',
        region_name: '',
        province_name: '',
        municipality_name: '',
        barangay_name: '',
        farm_address: '',
        logs: [],
        crop_details: [],
        marketing_programs: [],
        photo_url: ''
      },
      visitationLog: {
        customer: 0,
        visitation_date: this.dateFormatter(Date.now()),
        purpose: null,
        remarks: '',
        attachment_path: null,
        visitation_log_attachments: []
      },
      visitationLogAttachment: {
        attachment_path: null,
        preview_photo_name: null,
        preview_photo_path: null,
        attachments: []
      },
      customerPhoto: {
        customer: null,
        file: null
      },
      callRequest: {
        customer: null,
        customer_name: null,
        mobile_number: null,
        remarks: null
      },
      tableLead: {
        busy: false,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { key: 'index', label: '#', class: 'text-center' },
          { key: 'actions', class: 'text-center' },
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'mobile_number' },
          { key: 'first_name' },
          { key: 'last_name' },
          { key: 'submitted_by' },
          { key: 'province.province_name', label: 'province' },
          { key: 'municipality.municipality_name', label: 'municipality' },
          { key: 'barangay.barangay_name', label: 'barangay' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableCropDetails: {
        busy: false,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        fields: [
          { key: 'index', label: '#', class: 'text-center' },
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'crop_name', label: 'Crop' },
          { key: 'field_size_name', label: 'Field Size' },
          { key: 'number_of_hectares', label: 'Number of Hectares' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableMarketingPrograms: {
        busy: false,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        fields: [
          { key: 'index', label: '#', class: 'text-center' },
          { key: 'pivot.created_at', label: 'Enrollment Date', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'marketing_program_name', label: 'marketing program' }
        ]
      },
      tableCommunicationLog: {
        busy: false,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { key: 'index', label: '#', class: 'text-center' },
          { key: 'action', class: 'text-center' },
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'agent_name', label: 'agent' },
          { key: 'channel_name', label: 'channel' },
          { key: 'call_status' },
          { key: 'unsuccessful_reason_name', label: 'unsuccessful reason' },
          { key: 'disposition_type_name', label: 'disposition type' },
          { key: 'disposition_name', label: 'disposition' },
          { key: 'promotion_name', label: 'promotion' }
        ]
      },
      tableVisitationLog: {
        busy: false,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { key: 'index', label: '#', class: 'text-center' },
          { key: 'actions', class: 'text-center' },
          { key: 'visitation_date', formatter: this.dateFormatter },
          { key: 'user_name', label: 'lga / rfo' },
          { key: 'purpose_name', label: 'purpose' },
          { key: 'created_at', formatter: this.dateTimeFormatter },
          { key: 'created_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableVisitationLogAttachments: {
        fields: [
          { key: 'index', label: '#', class: 'text-center' },
          { key: 'action', class: 'text-center' },
          { key: 'file_original_name', label: 'file name' }
        ]
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    userScope () {
      const user = this.$store.getters['auth/user']

      return user?.user_scope
    },

    filteredVisitationPurposes () {
      const filter = [6]
      return this.list.purposes.filter(item => {
        return !filter.includes(item.value)
      })
    },

    filterCrops () {
      return this.list.crops.map(item => {
        return {
          crop_name: item.text,
          id: item.value
        }
      })
    },

    filterFieldSizes () {
      return this.list.field_sizes.map(item => {
        return {
          field_size_name: item.text,
          id: item.value
        }
      })
    },

    filteredCustomerCropDetails () {
      return this.customer.crop_details.filter(item => item.active === 1)
    },

    filteredCustomerMarketingPrograms () {
      return this.customer.marketing_programs.filter(item => item.active === 1)
    },

    rulesFile () {
      if (this.customerPhoto.file === null) {
        return 'required'
      }

      return ''
    },

    ModalAttachmentTitle () {
      return this.visitationLogAttachment.preview_photo_name
    }
  },

  watch: {
    'selected.province' (value) {
      this.filter.province = value?.id || 'all'
      this.getMunicipalities(value?.id || null)
      this.onFilterChanged()
    },

    'selected.municipality' (value) {
      this.filter.municipality = value?.id || 'all'
      this.onFilterChanged()
    },

    'selected.filter_crop' (value) {
      this.filter.filter_crop = value?.id || 'all'
      if (value === null) {
        this.selected.filter_field_size = null
      }
      this.onFilterChanged()
    },

    'selected.filter_field_size' (value) {
      this.filter.field_size = value?.id || 'all'
      this.onFilterChanged()
    }
  },

  mounted () {
    core.index()
    this.getVisitationPurposes()
    this.getUserProvinces()
    this.getFilterCrops()
    this.getFilterFieldSizes()
  },

  methods: {
    async tableLeadProvider (ctx) {
      this.tableLead.busy = true
      return await UserLeadService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_province: this.filter.province,
          filter_municipality: this.filter.municipality,
          filter_crop: this.filter.filter_crop,
          filter_field_size: this.filter.field_size
        })
      ).then(({ data }) => {
        this.tableLead.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableLead.busy = false
      })
    },

    async tableCommunicationLogProvider (ctx) {
      this.tableCommunicationLog.busy = true
      return await SharedLogService.getCustomerLogs(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          customer_id: this.customer.id
        })
      ).then(({ data }) => {
        this.tableCommunicationLog.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableCommunicationLog.busy = false
      })
    },

    async tableVisitationLogProvider (ctx) {
      this.tableVisitationLog.busy = true
      return await SharedLogService.getVisitationLogs(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          customer_id: this.customer.id
        })
      ).then(({ data }) => {
        this.tableVisitationLog.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableVisitationLog.busy = false
      })
    },

    async getUserProvinces () {
      await SharedListService.getUserProvinces().then(({ data }) => {
        this.list.provinces = data
      })
    },

    async getMunicipalities (province) {
      this.selected.municipality = null
      if (province) {
        this.list.municipalities = []
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalities = data
        })
      } else {
        this.list.municipalities = []
      }
    },

    async getVisitationPurposes () {
      await SharedListService.getVisitationPurposes().then(({ data }) => {
        this.list.purposes = data.map(item => {
          return {
            value: item.id,
            text: item.purpose_name
          }
        })
      })
    },

    async getFilterCrops () {
      await SharedListService.getCrops().then(({ data }) => {
        this.list.crops = data.map(item => {
          return {
            text: item.crop_name,
            value: item.id
          }
        })
      })
    },

    async getFilterFieldSizes () {
      await SharedListService.getFieldSizes().then(({ data }) => {
        this.list.field_sizes = data.map(item => {
          return {
            text: item.field_size_name,
            value: item.id
          }
        })
      })
    },

    onShowDetailForm (current) {
      this.customer.id = current.id
      this.customer.customer_type = current.customer_type
      this.customer.first_name = current.first_name
      this.customer.middle_name = current.middle_name
      this.customer.last_name = current.last_name
      this.customer.mobile_number = current.mobile_number
      this.customer.gender = current.gender
      this.customer.cluster_name = current?.cluster?.cluster_name
      this.customer.area_name = current.area?.area_name
      this.customer.region_name = current.region?.region_name
      this.customer.province_name = current.province?.province_name
      this.customer.municipality_name = current.municipality?.municipality_name
      this.customer.barangay_name = current.barangay?.barangay_name
      this.customer.farm_address = current.farm_address
      this.customer.logs = current.logs
      this.customerPhoto.customer = current.id

      this.customer.photo_url = ''
      this.customerPhoto.file = null

      if (current.photo) {
        this.customer.photo_url = this.getImageUrl(`${current.photo?.file_path}/${current.photo?.file_new_name}`)
      }

      this.customer.crop_details = current.crop_details.map(item => {
        return {
          id: item.id,
          active: item.active,
          crop: item.crop_id,
          crop_name: item.crop ? item.crop.crop_name : null,
          field_size: item.crop_field_size_id,
          field_size_name: item.field_size ? item.field_size.field_size_name : null,
          number_of_hectares: item.number_of_hectares,
          created_at: item.created_at,
          updated_at: item.updated_at
        }
      })
      this.customer.marketing_programs = current.marketing_programs

      this.$bvModal.show('modal-view-detail')
    },

    onShowLogAddForm (current) {
      this.state.previewing = false
      this.visitationLog.customer = current.id
      this.visitationLog.visitation_date = this.dateFormatter(Date.now())
      this.visitationLog.purpose = null
      this.visitationLog.remarks = ''
      this.visitationLog.attachment_path = null
      this.visitationLog.visitation_log_attachments = []
      this.visitationLogAttachment.attachment_path = null
      this.visitationLogAttachment.preview_photo_name = null
      this.visitationLogAttachment.preview_photo_path = null
      this.visitationLogAttachment.attachments = []
      this.$bvModal.show('modal-log-visit')
    },

    onPreviewVisitationLogAttachment (attachment) {
      this.visitationLogAttachment.preview_photo_name = attachment.file_original_name
      this.visitationLogAttachment.preview_photo_path = this.getImageUrl(
        this.state.previewing ? `${this.selected.visitation_log?.attachment_path}/${attachment.file_hash_name}` : `uploads/${this.visitationLog.attachment_path}/${attachment.file_hash_name}`
      )
      this.$bvModal.show('modal-preview-attachment')
    },

    async onValidateUploadVisitaionLogAttachments () {
      await this.$refs.formVisitationLogAttachment.validate().then(isAllFieldsAreValid => {
        if (isAllFieldsAreValid) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to upload photos?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true

              const payload = new FormData()
              this.visitationLogAttachment.attachments.forEach((attachment, index) => {
                payload.append(`attachments[${index}]`, attachment)
              })
              payload.append('attachment_path', this.visitationLogAttachment.attachment_path)

              return new Promise(resolve => {
                UserAttachmentService.uploadLogAttachment(payload).then(({ data }) => {
                  this.state.busy = false

                  this.visitationLogAttachment.attachment_path = this.visitationLog.attachment_path = data.attachment_path
                  this.visitationLog.visitation_log_attachments = data.attachments
                  this.visitationLogAttachment.attachments = []
                  this.$refs.formVisitationLogAttachment.reset()

                  this.$swal.fire({
                    icon: 'success',
                    title: 'Successful',
                    text: 'Photos has been uploaded.',
                    confirmButtonColor: '#06C270',
                    confirmButtonText: 'Dismiss'
                  })
                }).catch(error => {
                  this.state.busy = false
                  if (error instanceof AxiosError) {
                    if (error.code === 422) {
                      resolve(error)
                    }
                  }
                })
              })
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        }
      })
    },

    async onDeleteVisitationLogAttachment (attachment) {
      this.$swal.fire({
        icon: 'question',
        title: 'Question',
        text: 'Do you really want to delete this photo?',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#FF2929',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.state.busy = true
          const payload = {
            attachment_path: this.visitationLog.attachment_path,
            file_hash_name: attachment.file_hash_name
          }
          return new Promise(resolve => {
            UserAttachmentService.deleteLogAttachment(payload).then(({ data }) => {
              this.visitationLog.visitation_log_attachments = this.visitationLog.visitation_log_attachments.filter(attachment => attachment.file_hash_name !== data.file_hash_name)

              this.state.busy = false
              resolve(data)
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              })
            })
          })
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async onDeleteAllVisitaionLogAttachment () {
      this.$swal.fire({
        icon: 'question',
        title: 'Question',
        text: 'Do you really want to delete uploaded photos?',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#FF2929',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.state.busy = true
          const payload = {
            attachment_path: this.visitationLog.attachment_path
          }
          return new Promise(resolve => {
            UserAttachmentService.deleteAllLogAttachment(payload).then(({ data }) => {
              this.state.busy = false
              this.visitationLog.visitation_log_attachments = []
              resolve(data)
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              })
            })
          })
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formLogVisit.validate().then(async success => {
        if (success) {
          const title = 'Do you really want to submit log?'
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserLogService.post(this.visitationLog).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-log-visit')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.formLogVisit.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onConfirmAddCallRequest (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formCallRequest.validate().then(async success => {
        if (success) {
          const title = 'Do you really want to request a call for this customer?'
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onCallRequestFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onCallRequestFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserCallRequestService.post(this.callRequest).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-add-call-request')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.formCallRequest.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onUploadPhotoButtonClick () {
      this.$refs.formUploadPhoto.validate().then(async success => {
        if (success) {
          const title = 'Do you really want to upload this photo?'
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onCustomerPhotoPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onCustomerPhotoPostSubmit () {
      const customerPhoto = new FormData()
      customerPhoto.append('file', this.customerPhoto.file[0])
      customerPhoto.append('customer', this.customerPhoto.customer)
      return new Promise(resolve => {
        this.state.busy = true
        UserAttachmentService.uploadCustomerPhoto(customerPhoto).then(({ data }) => {
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.customer.id })
            row.photo = data.customer_photo
            this.customer.photo_url = this.getImageUrl(`${data.customer_photo?.file_path}/${data.customer_photo?.file_new_name}`)
            this.customerPhoto.file = null
            this.$refs.formUploadPhoto.reset()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.formUploadPhoto.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onViewCommunicationLog (current) {
      this.selected.log = { ...current }
      this.$bvModal.show('modal-view-communication-log')
    },

    onViewVisitationLog (current) {
      this.state.previewing = true
      this.selected.visitation_log = { ...current }
      this.$bvModal.show('modal-view-visitation-log')
    },

    onShowAddCallRequest (current) {
      this.callRequest.customer = current.id
      this.callRequest.customer_name = `${current.first_name} ${current.last_name}`
      this.callRequest.mobile_number = current.mobile_number
      this.callRequest.remarks = null
      this.$bvModal.show('modal-add-call-request')
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }

  }
}
</script>
<style>
#modal-preview-attachment .modal-xl {
  width:100vw;
  max-width: none;
  min-height: 100vh;
  margin: 0
}
#modal-preview-attachment .modal-content {
  min-height: 100vh;
}
</style>
